// import { Controller } from "stimulus"

// export default class extends Controller {
//   static targets = [
//     "establishmentOrDivision",
//     "search",
//     "employeeShift",
//     "employeeType",
//     "workGroup",
//     "department",
//     "supervisor",
//     "downloadLink",
//   ]

//   initialize() {
//     // Each change of the filter
//     this.updateLink()
//   }
  
//   updateLink() {
//     this.downloadLinkTarget.href = training_job_qualification_qr_business_cards_path(account_id: this.accountId, {
//       establishment_or_division: this.establishmentOrDivisionTarget.value,
//       search: this.searchTarget.value,
//       employee_shift: this.employeeShiftTarget.value,
//       employee_type: this.employeeTypeTarget.value,
//       work_group: this.workGroupTarget.value,
//       department: this.departmentTarget.value,
//       supervisor: this.supervisorTarget.value,
//       format: "pdf",
//     })
//   }
// }
